var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-8", style: { maxWidth: "1318px" } },
    [
      _c("v-breadcrumbs", {
        staticClass: "pa-0 ma-0",
        attrs: { items: _vm.breadcrumbs },
      }),
      _c("div", { staticClass: "font-weight-bold text-h4" }, [
        _vm._v(_vm._s(_vm.$t("label.personalFinancing"))),
      ]),
      _c("div", { staticClass: "my-4 personal-loan__header-container" }, [
        _c(
          "div",
          {
            staticClass:
              "my-4 personal-loan__banner-container overflow-x-hidden d-flex rounded",
          },
          [
            _c("img", {
              staticClass: "personal-loan__banner",
              attrs: {
                src: _vm.bannerUrl,
                alt: "Personal Loan | Banner",
                title: "Personal Loan | Banner",
              },
            }),
          ]
        ),
        _c(
          "div",
          { staticClass: "personal-loan__banner-header text-uppercase" },
          [
            _c("h3", { staticClass: "d-block d-lg-none mb-2" }, [
              _c("p", { staticClass: "text-h5 font-weight-bold mb-0" }, [
                _vm._v(_vm._s(_vm.$t("label.personalLoanHeader"))),
              ]),
            ]),
            _c("p", { staticClass: "d-block d-lg-none mb-0 caption" }, [
              _vm._v(_vm._s(_vm.$t("label.personalLoanSubheader"))),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "card-container" },
        _vm._l(_vm.personalLoans, function (card, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "d-flex flex-column flex-md-row align-center",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex overflow-hidden border rounded-xl personal-loan__underlay-desktop mb-2 mb-md-8",
                },
                [
                  _c("img", {
                    staticClass: "personal-loan__icon-desktop",
                    attrs: {
                      src: card.imgUrl,
                      title: card.title,
                      alt: card.title + " | Image",
                    },
                  }),
                ]
              ),
              _c("div", { staticClass: "px-4 flex-grow-1 align-self-start" }, [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "font-weight-bold text-md-h5 text-h6" },
                    [_vm._v(_vm._s(card.title))]
                  ),
                  _c("div", { staticClass: "my-4" }, [
                    _c(
                      "ul",
                      { staticClass: "order-3 order-sm-2 mb-4" },
                      _vm._l(card.features, function (feature) {
                        return _c(
                          "li",
                          {
                            key: feature,
                            staticClass: "text-body-2 text-md-body-1",
                          },
                          [_vm._v(" " + _vm._s(feature) + " ")]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mb-4 mt-0 mt-sm-4 order-2 order-sm-3 personal-loan__requirements",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-italic font-weight-bold text-body-2 text-md-body-1",
                          },
                          [_vm._v(_vm._s(_vm.$t("label.requirements")))]
                        ),
                        _vm._l(card.requirements, function (requirement) {
                          return _c(
                            "div",
                            {
                              key: requirement,
                              staticClass:
                                "font-italic text-body-2 text-md-body-1",
                            },
                            [_vm._v(_vm._s(requirement))]
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-block d-md-none text-body-2 font-italic",
                      },
                      [
                        _vm._l(card.documents, function (document, i) {
                          return [
                            _c(
                              "a",
                              {
                                key: document.name,
                                attrs: {
                                  href: document.link,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(i === 0 ? "*" : "") +
                                    _vm._s(document.name)
                                ),
                              ]
                            ),
                            i < card.documents.length - 1
                              ? _c("span", {
                                  key: document.name + "-separator",
                                  domProps: { textContent: _vm._s(" | ") },
                                })
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "my-4 caption font-italic" }, [
                _c(
                  "div",
                  [
                    _c("app-button", {
                      attrs: {
                        title: _vm.$t("action.applyOnlineNow"),
                        action: card.onClick,
                        type: "default",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-none d-md-block" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("label.clickHereFor")) + _vm._s(" ")
                      ),
                    ]),
                    _vm._l(card.documents, function (document, i) {
                      return [
                        _c(
                          "a",
                          {
                            key: document.name,
                            staticClass: "font-italic",
                            attrs: { href: document.link, target: "_blank" },
                          },
                          [_vm._v(_vm._s(document.name))]
                        ),
                        _c("span", {
                          key: document.name + "-separator",
                          domProps: {
                            textContent: _vm._s(
                              _vm.getSeparators(i, card.documents.length)
                            ),
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
              index < _vm.personalLoans.length - 1
                ? _c("v-divider", {
                    staticClass: "align-self-stretch d-md-none py-2",
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }